// Deps
import { useState, useEffect, useRef, useCallback } from 'react'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'
import Listing from '/views/sections/listing'
import ProductCarousel from '/views/sections/product-carousel'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';

// Hooks
import useQuery from '/hooks/query'

// Services
import productServices from '/services/product'
import siteServices from '/services/site'

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state'
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useChildren } from '/utilities/children/use-children';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';

// Shared Endpoints
const getSearchProducts = (searchParam, query, ctx) => {
	return productServices.getSearchProducts(searchParam, query, ctx);
}

const getSearchFilters = (searchParam, ctx) => {
	return productServices.getSearchFilters(searchParam, ctx);
}

const getRecentProducts = () => {
	return siteServices.getLastVisitedProducts();
}

const generateHead = (searchParam, getRoutePath) => ({
	title: `"${searchParam}" Arama Sonuçları`,
	description: `"${searchParam}" ile ilgili arama sonuçları.`,
	canonical: getRoutePath('search', { search: searchParam }),
});

const defaultQuery = {
	page: '1',
	order: 'ovd',
}

const Search = ({ match }) => {
	const _mounted = useRef(false);
	
	const { setError, setNotFound } = useGlobalState();
	const { childSelection } = useChildren();
	const { getRoutePath, redirect } = useNavigator();
	const { triggerEvent } = useGlobalEvents();
	const { setHead } = useHead();

	const { search: searchParam, query: queryString } = match.params;
	
	const paramsCache = useRef(match.params);
	
	const [query] = useQuery(queryString, defaultQuery, undefined, undefined, childSelection, searchParam);
	
	const [filters, setFilters] = useState(null);
	const [pagination, setPagination] = useState(false);
	const [products, setProducts] = useState(false);
	
	// Supporting Data
	const [recentProducts, setRecentProducts] = useState(false);
	
	const fetchedSearchParam = useRef(false);
	const fetchedQuery = useRef(false);

	const getData = (search, query) => {
		setProducts(false);

		getSearchProducts(search, query).then((payload) => {
			if (_mounted.current) {
				fetchedSearchParam.current = search;
				fetchedQuery.current = query;
				setPagination(payload.pagination);
				setProducts(payload.products);
			}
		}).catch((e) => {
			if (_mounted.current) {
				console.warn('Search products fetch error', e);
				setError();
			}
		})
	};

	const getFilters = (id, inStock) => {
		setFilters(false);
		getSearchFilters(id, inStock).then((payload) => {
			if (_mounted.current) {
				setFilters({ id: id, inStock: inStock, filters: payload});
			}
		}).catch((e) => {
			if (_mounted.current) {
				console.warn('Category filters fetch error', e);
				if(e.status === 404) {
					setNotFound();
				}
				else {
					setError();
				}
			}
		})
	}

	const updateQuery = useCallback((updatedQuery) => {
		let newQuery = [];

		for (const param in updatedQuery) {
			if (param !== 'primaryVariable' && updatedQuery[param].length && defaultQuery[param] !== updatedQuery[param]) {
				newQuery.push(param + '=' + updatedQuery[param]);
			}
		}

		redirect(
			'search',
			{
				...paramsCache.current,
				query: (newQuery.length ? newQuery.join('&') : undefined),
			},
			undefined,
			undefined,
			false
		);
	}, []);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	useEffect(() => {
		paramsCache.current = match.params;
	}, [match.params])
	
	useEffect(() => {
		if(query?.primaryVariable) {
			const {primaryVariable, ...newQuery} = query;
			
			const idChanged = primaryVariable !== fetchedSearchParam.current
			if (!isExact(newQuery, fetchedQuery.current) || idChanged) {
				getData(primaryVariable, newQuery);
	
				if (idChanged) {
					setPagination(false);
					// getFilters(primaryVariable);
				}
			}
		}
	}, [query])

	useEffect(() => {
		if(query.primaryVariable && filters !== false && !(filters?.id === query.primaryVariable && (query.stock === "1") === filters?.inStock)) {
			getFilters(query.primaryVariable, query.stock === "1");
		}
	}, [filters, query])

	useEffect(() => {
		if(searchParam) {
			setHead(generateHead(searchParam, getRoutePath))
		}
	}, [searchParam])

	// Supporting Data
	useEffect(() => {
		if (_mounted.current && !recentProducts) {
			getRecentProducts().then((payload) => {
				if (_mounted.current) {
					setRecentProducts(payload);
				}
			}).catch(() => {
			})
		}
	}, [recentProducts]);

	// Analytics
	useEffect(() => {
		if(products?.length) {
			triggerEvent('listingDataUpdate', {
				key: `Arama Sonuçları: ${searchParam}`,
				products,
				params: {
					taxonomy: [`Arama: ${searchParam}`]
				},
			});
		}
	}, [products, searchParam])


	return (
		<main className="page listing">
			<Breadcrumbs data={[{name: 'Arama Sonuçları'}]} />
			<Listing
				page="search"
				onUpdateQuery={updateQuery}
				query={query}
				products={products}
				pagination={pagination}
				filters={filters?.filters}
				title="Arama Sonuçları"
				description={false} />
			<ProductCarousel
				tabs
				className="listing-recentproducts"
				data={[
					{
						title: 'Son Gezilen Ürünler',
						icon: 'history',
						key: 'recent',
						products: recentProducts ? recentProducts : false,
					}
				]} />
		</main>
	)
}

Search.defaultProps = {
}

export default Search;